import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink} from "@fortawesome/pro-solid-svg-icons"
import IdahoMedalofHonorModal from "./IdahoMedalofHonorModal"

function Modal(props) {
    const [modalShow, setModalShow] = React.useState(false)

    return (
        <>
        <span onClick={() => setModalShow(true)} style={{
                cursor: 'pointer',
            }}
            className="recipient"
            role="button"
            aria-label={`Open details for ${props.recepientName}`}
            >
        {props.recepientName}
            <span>
                <FontAwesomeIcon
                    icon={faExternalLink}
                    style={{
                        fontSize: '14px',
                        marginLeft: '10px'
                    }}
                    aria-hidden="true"
                    
                />
            </span>
            </span>

            <IdahoMedalofHonorModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
                recepientName={props.recepientName}
                modalContent={props.modalContent}
            />
        </>
    )
}

export default Modal